<script>
import api from '../../../service/api';
export default {
  name: 'modal-view-photo-history',

  data() {
    return {
      dialog: false,
      loading: false,
      photoUrl: undefined,
    };
  },

  watch: {
    dialog: function (newValue) {
      if (!newValue) {
        this.photoUrl = undefined;
      }
    },
  },

  methods: {
    async view(photoUrl) {
      this.dialog = true;
      this.photoUrl = await api({
        method: 'get',
        url: photoUrl,
        responseType: 'blob',
      })
        .then(function (resp) {
          const blob = resp.data;
          const objectUrl = URL.createObjectURL(blob);
          return objectUrl;
        })
        .catch(function () {
          return null;
        });
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" width="auto" scrollable>
    <v-card width="auto">
      <v-img v-if="photoUrl" :src="photoUrl" />
      <v-container v-else class="pa-8">
        <p>Carregando imagem ...</p>
        <v-progress-linear indeterminate></v-progress-linear>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style scoped>
img {
  width: 100%;
}
</style>
